/*location detail */
.locationDetail {
    width: 100%;
    padding-left: 2%;
    clear: both;
    background-color: #ebedf0;
    border-bottom: solid #d7d9dc 1px;
    overflow: hidden;
    background-size: cover;
}

.locationDetail h2 {
    text-align: center;
    font-size: 18px;
}

.locationDetail h2 a {
    color: #e79216;
    font-weight: 400;
}

.locationDetail h2 a:hover {
    font-weight: bold;
}

.locationDetail table {
    width: 98%;
    table-layout: fixed;
}

.locationDetail tbody td {
    color: #e79216;
    background-color: #2d2928;
    padding: 10px;
    margin: 15px;
    font-size: 16px;
}

/* desktop screen */
@media only screen and (min-width: 800px) {
    .locationDetail tbody td {
        color: #e79216;
        background-color: #2d2928;
        padding: 20px;
        margin: 20px;
        font-size: 20px;
    }

    .locationDetail h2 {
        text-align: center;
        font-size: 1.5em;
    }
}