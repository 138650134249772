.dogboarding {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #ebedf0;
    overflow: hidden;
    background-size: cover;
}

.dogboarding h1 {
    width: 90%;
    z-index: 0;
    margin: 3% auto;
    color: #555;
    z-index: 0;
    font-size: 18px;
}

.dogboarding h2 {
    width: 90%;
    color: #555;
    margin: 2% auto;
    font-size: 14px;
}

.dogboarding h1 a {
    color: #e79216;
    font-weight: 400;
}

.dogboarding h1 a:hover {
    font-weight: normal;
}

/* desktop screen */
@media only screen and (min-width: 800px) {
    .dogboarding h1 {
        width: 80%;
        z-index: 0;
        margin: 3% auto;
        color: #555;
        z-index: 0;
        font-size: 30px;
    }

    .dogboarding h2 {
        width: 80%;
        color: #555;
        margin: 2% auto;
        font-size: 18px;
    }
}