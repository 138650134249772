
.shoppingDetail h2 {
    margin: 18px;
    grid-area: header;
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 40px;
}
.product-image {
    width: 250px;
    height: 250px;
    object-fit: scale-down;
}
.shoppingDetail table {
    width: 70%;
    border-collapse: collapse;
    margin: 2% 10%;
    table-layout: auto;
}

.shoppingDetail td:nth-child(1) { /* Selects the first column */
    width: 35%;
}

.shoppingDetail td:nth-child(2) { /* Selects the second column */
    font-size: 20px;
    width: 45%;
}

.shoppingDetail td:nth-child(3)  {
    width: 10%;
    font-size: 30px;
    align-content: center;
}

.shoppingDetail td:nth-child(4) {
    width: 10%;
}

.shoppingDetail tr:nth-child(even) {
    background-color: #f2f2f2;
}
.shoppingDetail button {
    color: #2d2928;
    font-weight: bold;
    background-color: #e79216;
    font-size: 14px;
    padding: 5px;
    border: 20px;
    border-radius: 45px;
}

.shoppingDetail button:hover {
    font-weight: bold;
    color: white;
    background-color: #2d2928;
}

.shoppingDetail th {
    background-color: #f2f2f2;
    padding: 10px;
    text-align: left;
}

.shoppingDetail td {
    border-top: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}
@media only screen and (max-width: 800px) {

    .shoppingDetail h2 {
        grid-area: header;
        text-align: center;
        font-size: 30px;
    }

    .shoppingDetail table {
        width: 60%;
        border-collapse: collapse;
        table-layout: auto;
    }

    td, th {
        overflow-wrap: break-word;
        font-size: 4px;
    }

    .shoppingDetail td:nth-child(1) { /* Selects the first column */
        width: 35%;
    }

    .shoppingDetail td:nth-child(2) { /* Selects the second column */
        display: none;
    }

    .shoppingDetail td:nth-child(3)  {
        width: 10%;
        font-size: 30px;
        align-content: center;
    }

    .shoppingDetail td:nth-child(4) {
        width: 10%;
    }
}
